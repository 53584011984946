import BigNumber from 'bignumber.js'
import { environment } from 'src/environments/environment'
import { IUser, Transaction, Wallet } from './api-interfaces'
import { formatAccountNumber } from './common.mixin'

export function calculateFee(transaction: Transaction): string {
    const { amount, precision } = getAmountAndPrecision(transaction)
    let relativeFeeAmount = new BigNumber(amount).times(transaction.relativeFee)
    // Only use this for preview
    if (!transaction.id && transaction.settledAmount) {
        // Factor fixed fee to relative fee computation
        const fixedAmount = new BigNumber(amount).plus(transaction.fixedFee)
        relativeFeeAmount = fixedAmount
            .div(new BigNumber(1).minus(new BigNumber(transaction.relativeFee)))
            .minus(fixedAmount)
    }
    const roundingMode = environment.layout === 'metal' ? 4 : 1
    return new BigNumber(transaction.fixedFee || 0).plus(relativeFeeAmount).toFixed(precision, roundingMode)
}

/**
 * Calculates amount which will be credited to the receiver wallet
 */
export function calculateCreditableAmount(transaction: Transaction): string {
    const { amount, precision } = getAmountAndPrecision(transaction)
    if (['card-order', 'account-fee', 'payment', 'invoice'].includes(transaction.type)) {
        return new BigNumber(0).toFixed(precision, 1)
    }
    const total = new BigNumber(amount).minus(
        !transaction.id && transaction.settledAmount ? 0 : calculateFee(transaction)
    )
    const roundingMode = environment.layout === 'metal' ? 4 : 1
    return (total.isGreaterThan(0) ? total : new BigNumber(0)).toFixed(precision, roundingMode)
}

/**
 * Calculates amount which will be deducted from the sender wallet
 */
export function calculateDeductableAmount(transaction: Transaction): string {
    const { amount, precision } = getAmountAndPrecision(transaction)
    if (['card-order', 'account-fee', 'payment', 'invoice'].includes(transaction.type)) {
        return new BigNumber(calculateFee(transaction)).toFixed(precision, 1)
    }
    const roundingMode = environment.layout === 'metal' ? 4 : 1
    return new BigNumber(amount)
        .plus(!transaction.id && transaction.settledAmount ? calculateFee(transaction) : 0)
        .toFixed(precision, roundingMode)
}

function getAmountAndPrecision(transaction: Transaction): { amount: string; precision: number } {
    const amount = transaction.amount || transaction.requestedAmount || '0'
    let precision
    if (transaction.precision !== undefined) {
        precision = transaction.precision
    } else if (transaction.currency) {
        precision = transaction.currency.decimalPlaces
    } else {
        precision = 8
    }
    return { amount, precision }
}

export function buildWalletOwnerAccountNumber(wallet: Wallet | null, fallback: string): string {
    return wallet ? (wallet.user ? formatAccountNumber(wallet.user.accountNumber) : fallback) : 'Mint'
}

export function isReceiver(transaction: Transaction, user: IUser | null): boolean {
    return (
        !!transaction.counterWallet &&
        !!transaction.counterWallet.user &&
        !!user &&
        transaction.counterWallet.user.id === user.id
    )
}

export function isTransactionProcessable(transaction: Transaction): boolean {
    return !transaction.automated || transaction.faulty
}
